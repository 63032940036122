import React from 'react'
import { contentForMarketingWording } from './form-helpers'

const MarketingContentText = () => (
  <>
    {contentForMarketingWording}
  </>
)

export default MarketingContentText
