import React from 'react'
import './contact-form.sass'

const Checkbox = ({ name, value, handleChange, children, required, className }) => (
  <div className={`Checkbox form-group ${className}`}>
    <div className="form-check">
      <input
        name={name}
        type="checkbox"
        className="form-check-input"
        id={name}
        checked={value}
        onChange={handleChange} />
      <label
        className="form-check-label"
        htmlFor={name}>
        {children}
        {required && '*'}
      </label>
    </div>
  </div>
)

export default Checkbox
