import React from 'react'
import { strToEnglish } from './form-helpers'

const getPlaceholder = (children, name, required) => {
  let str = children
    ? children
    : strToEnglish(name)
  str += required ? ' *' : ''
  return str
}

const Input = (props) => (
  <div className={props.className ? `form-group ${props.className}` : `form-group form-group__${props.name}`}>
    <input
      name={props.name}
      type={ props.type ? props.type : 'text' }
      className={ props.inputName?  props.inputName : 'form-control'}
      id={props.id ? props.id : props.name}
      placeholder={props.placeholder ? props.placeholder : getPlaceholder(props.children, props.name, props.required)}
      value={props.value}
      onChange={props.handleChange}
      required={props.required} />
  </div>
)

export default Input
