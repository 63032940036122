import React from 'react'
import ContactForm from 'components/forms/contact-form'
import Image from 'components/image/image'
import withSectionTracking from 'helpers/higher-order-components/section-tracking.hoc'
import './get-in-touch.sass'

const GetInTouch = () => (
  <section className="GetInTouch k-anchor__section k-section">
    <div id="k-anchor__get-in-touch" />
    <Image image_url="layout/bg-hexagon-filled.svg"
                 className="GetInTouch__background"
                 alt="Get in touch background" />
    <div className="container-fluid">
      <div className="k-row d-md-flex">
        <div className="GetInTouch__text col-12 col-sm-12 col-md-5">
          <h2>
            Get In Touch
          </h2>
          <p className="GetInTouch__p mb-5">
            Whether you're just starting your AI journey or looking for support in improving your existing delivery capability, please reach out.
          </p>
        </div>
        <div className="col-12 col-sm-12 col-md-7 px-4">
          <ContactForm/>
        </div>
      </div>
    </div>
  </section>
)

export default withSectionTracking(React.memo(GetInTouch))
