import React from 'react'
import Image from 'components/image/image'
import './thank-you.sass'

export const ThankYou = ({ compact, landing_page }) =>  (
  <div className={`ThankYou text-center
      ${ compact
        ? 'ThankYou--compact'
        : landing_page
        ? 'ThankYou--landing-page'
        :'ThankYou--regular m-lg-4'}`}>
    {compact ? <h4>Thank you!</h4> : <h3>Thank you!</h3> }
    {compact ? null : <h4 className="mt-3">A Kortical team member will be in touch shortly.</h4>}
    <Image image_url="content/form-thank-you-smiley/form-thank-you-smiley.png"
           alt="Thank you smiley" className="mt-4"/>
  </div>
)


export default ThankYou
