import React from 'react'

const getErrors = (errors) => {
  return errors.map((error, i) => {
    return (
      <span key={i}>{error.message}</span>
    )
  })
}

const ResponseMessage = (props) => (
  <div className="ResponseMessage mt-3">
    { (props.response.inlineMessage || props.response.message) &&
      <div className={`
          ResponseMessage__message
          ${props.status === 200 ? 'text-success' : 'text-danger'}
        `}>
        {props.response.inlineMessage || props.response.message}
      </div>
    }
    { props.response.errors &&
      <div className="ResponseMessage__error text-danger">
        {getErrors(props.response.errors)}
      </div>
    }
  </div>
)

export default ResponseMessage
