import React from 'react'
import { consentForProcessWording } from './form-helpers'

const MarketingTermsText = (props) => (
  <p
    className={props.className}
    dangerouslySetInnerHTML={{ __html: consentForProcessWording }} />
)

export default MarketingTermsText
