import React from 'react'
import { connect } from 'react-redux'
import { ADD_OR_UPDATE_SECTION_TRACKING } from 'store/actions'
import { compose } from 'redux'
import { Event } from 'helpers/analytics/event-tracking'

const interval = 5

const withSectionTracking = Component => {
  return class SectionTracking extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        time: 0,
        isOn: false,
        start: 0,
        timeIncrements: 0,
        sendSectionData: false,
        lastSentSectionDataTime: null
      }

      this.startTimer = this.startTimer.bind(this)
      this.stopTimer = this.stopTimer.bind(this)
    }

    UNSAFE_componentWillMount() {
      // If child component is called but section-tracking HOC shouldn't be used
      if(!this.props.sectionId) {
        return <Component {...this.props} />
      }
      this.setSectionTimeToStorageInterval()
      const windowDocumentGlobal = typeof window !== 'undefined' && typeof(document) !== 'undefined'
      if (windowDocumentGlobal) {
        window.addEventListener('scroll', this.sectionIsScrolledIntoView)
        document.addEventListener("visibilitychange", this.handleVisibilityChange)
      }
    }

    componentDidMount() {
      if(this.props.sectionId) {
        this.sectionIsScrolledIntoView()
      }
    }

    componentWillUnmount() {
      const windowDocumentGlobal = typeof window !== 'undefined' && typeof(document) !== 'undefined'
      if (windowDocumentGlobal) {
        window.removeEventListener('scroll', this.sectionIsScrolledIntoView)
        document.removeEventListener("visibilitychange", this.handleVisibilityChange)
      }
      clearInterval(this.timeToStorageInterval)
      clearInterval(this.timer)
    }

    amountInterval(num) {
      if (num >= 1 && num < 5) {
        return Math.floor(num)
      }
      return Math.floor(num / interval) * interval
    }

    setSectionTimeToStorageInterval() {
      this.timeToStorageInterval = setInterval(() => {
        const timeInSeconds = this.state.time / 1000
        const timeInInterval = this.amountInterval(timeInSeconds)

        if (timeInInterval > this.state.timeIncrements) {
            this.setState({
              timeIncrements: timeInInterval
            }, () => {
              this.props.addSectionTracking({id: this.props.sectionId, time: timeInInterval})
            })
          }
        }, 5000)
    }

    startTimer() {
      if (this.state.lastSentSectionDataTime === null ) {
        this.setState({
          lastSentSectionDataTime: 0
        })
      }

      this.setState({
        isOn: true,
        time: this.state.time,
        start: Date.now() - this.state.time
      })

      this.timer = setInterval(() => {
      if (this.state.isOn) {
          this.setState({
            time: Date.now() - this.state.start
          })
        }
      }, 1000)
    }

    stopTimer() {
      this.setState({isOn: false})
      clearInterval(this.timer)
    }

    handleVisibilityChange = () => {
      if(document.hidden) {
        this.stopTimer()
      }
    }

    sectionIsScrolledIntoView = () => {
      if (typeof window !== 'undefined') {
        const section = document.getElementById(this.props.sectionId)
        const section_rect = section.getBoundingClientRect()
        const minimum_percent_visible = 0.25
        const section_below_visible_window = section_rect.top >= (0 -  section_rect.height * minimum_percent_visible)
        const section_above_visible_window = section_rect.bottom <= (window.innerHeight + section_rect.height * minimum_percent_visible)

        if (section_above_visible_window && section_below_visible_window){
          this.sendSectionTrackingOnSectionEnter()
          this.startTimer()
          this.setState({sendSectionData: true})
        } else {
          this.stopTimer()
          if (this.state.sendSectionData) {
            this.sendSectionTrackingOnSectionLeave()
            this.setState({sendSectionData: false})
          }
        }
      }
    }

    sendSectionTrackingOnSectionEnter() {
      if (!this.state.sendSectionData && this.state.lastSentSectionDataTime === null) {
        Event(
          'Section-Tracking',
          this.props.sectionId,
          `0`,
          0
        )
      }
    }

    sendSectionTrackingOnSectionLeave() {
      if (this.props.sectionTracking.length === 0){
        return
      }

      const currentSectionTracking = this.props.sectionTracking[this.props.sectionTracking.length-1]

      currentSectionTracking.forEach(section => {
        const id_equal = section.id === this.props.sectionId
        const time = section.total_time
        ? section.time + section.total_time
        : section.time
        const time_changed = time !== this.state.lastSentSectionDataTime

        if (id_equal && time_changed ) {
          Event(
            'Section-Tracking',
            section.id,
            `${time <= 5 ? '0'+time : time}`,
            time
          )
          this.setState({
            lastSentSectionDataTime: time
          })
        }
      })
    }

    render() {
      return (
      <div id={this.props.sectionId}>
        <Component {...this.props} />
      </div>
    )}
  }

}

const mapStateToProps = state => ({
  sectionTracking: state.analyticsReducer.sectionTracking
})

const mapDispatchToProps = dispatch => ({
  addSectionTracking: (section) => {
    dispatch(ADD_OR_UPDATE_SECTION_TRACKING(section))
  }
})

const composedWithSectionTracking = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSectionTracking
)

export default composedWithSectionTracking