import React from 'react'
import HubspotForm from './hubspot-form'
import Loader from 'components/loaders/loader'
import Input from './input'
import Textarea from './textarea'
import Checkbox from './checkbox'
import ResponseHandler from './response-handler'
import ContactErrors from "./contact-errors";
import MarketingContentText from './marketing-content-text'
import MarketingTermsText from './marketing-terms-text'
import ThankYou from 'components/thank-you/thank-you'
import FadeInOutAnimation from 'helpers/animations/fade-in-out-animation'
import { Event } from 'helpers/analytics/event-tracking';
import { findLastIndex } from 'helpers/functions'
import './contact-form.sass'

const initalFieldsState = {
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  message: '',
  request_a_demo: false,
  contentForMarketing: false,
  ab_testing: process.env.DEPLOYED_BRANCH
}

const initialState = {
  fields: initalFieldsState,
  errors: {},
  form_valid: false,
  errorActive: false,
  response: null,
  status: 0,
  loading: false,
  thank_you_message: false,
  start_time: ""
}

class Form extends HubspotForm {

  constructor(props){
    super(props)
    this.formId = process.env.GATSBY_ACTIVE_ENV === 'production' ? 'ffa6e3da-8492-434d-950c-1723a01a2f0c' : 'b4c12644-73d6-4176-b4f8-289b519fe852'
    this.contentForMarketingId = 5472884
    this.state = initialState
    this.count = 0
  }

  getTime = () => {
    let date = new Date();
    let str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    return str;
  }

  handleUserInput = (e) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name;
    this.setState({fields: {
                          ...this.state.fields,
                          [name]: value
                        }},
              () => { this.handleValidation(name, value) });
  }

  handleValidation = (name, value) => {
    this.count++
    let errors = this.state.errors;
    let formValid = false;
    let errorActive = false;

    if (this.count === 1) {
      this.setState({start_time: this.getTime()})
    }

    //First Name
    if(name === "firstname") {
      let whitespace = /^\s+$/;

      if (value === "") {
        errors["firstname"] = "First name cannot be empty";
      } else if (whitespace.test(value)) {
        errors["firstname"] = "First name cannot only have spaces";
      } else {
        errors["firstname"] = "";
      }
    }

    //Email
    if(name === "email") {
      let lastAtPos = value.lastIndexOf("@");
      let lastDotPos = value.lastIndexOf(".");

      if (value === "") {
        errors["email"] = "Email cannot be empty";
      } else if (!(
                  lastAtPos < lastDotPos &&
                  lastAtPos > 0 &&
                  value.indexOf("@@") == -1 &&
                  lastDotPos > 2 &&
                  value.length - lastDotPos > 2
                )) {
        errors["email"] = "Email is not valid";
      } else {
        errors["email"] = "";
      }
    }

    //Phone
    if(name === "phone") {
      let phoneRegex = /^[\d+\-\(\)\s]{6,20}/;

      if (value !== ""
          && (value.length <= 6 || value.length >= 20)
          && !phoneRegex.test(value))
      {
        errors["phone"] = "Phone number is not valid";
      } else {
        errors["phone"] = "";
      }
    }

    // Errors Active?
    for (let key of Object.keys(errors)) {
      if (errors[key] !== "") {
        errorActive = true;
      }
    }

    if (!errorActive) {
      if (this.state.fields.firstname !== "" && this.state.fields.email !== "") {
        formValid = true;
      }
    }

    this.setState({
      errors: errors,
      form_valid: formValid,
      errorActive: errorActive
    });
  }

  showThankYouMessage() {
    const time_shown = 7000;

    this.setState({
      thank_you_message: true
    })

    setTimeout(() => this.setState({thank_you_message: false}), time_shown)
  }

  sendLastClickedCTA(){
    // Finds last clicked CTA if both were clicked or if only one was
    // call-to-action (CTA)
    if (window && window.dataLayer) {
      const navCTAIndex = findLastIndex(window.dataLayer, 'gtm.elementId', "free-trial-header");
      const heroCTAIndex = findLastIndex(window.dataLayer, 'gtm.elementId', "hero__get-in-touch");

      if (navCTAIndex === -1 && heroCTAIndex === -1) {
        return;
      }

      let lastClickedCTA;
      let is_heroCTA;

      if (navCTAIndex !== -1 && heroCTAIndex !== -1) {
        const lastClickedCTAIndex = heroCTAIndex > navCTAIndex ? heroCTAIndex : navCTAIndex;
        is_heroCTA = heroCTAIndex > navCTAIndex;
        lastClickedCTA = window.dataLayer[lastClickedCTAIndex];
      }
      else {
        const CTAIndex = heroCTAIndex !== -1 ? heroCTAIndex : navCTAIndex;
        is_heroCTA = heroCTAIndex !== -1;
        lastClickedCTA = window.dataLayer[CTAIndex];
      }

      const lastClickedCTAText = lastClickedCTA['gtm.element'].innerText;

      Event(
        `Contact form submission from ${is_heroCTA ? 'hero' : 'nav'} call-to-action`,
        `${is_heroCTA ? 'hero' : 'nav'}_text: ${lastClickedCTAText}`
      );
    }
  }

  successCallback() {
    if (this.state.status === 200) {
      this.setState(initialState);
      this.showThankYouMessage();

      Event('gtm-contact-form', 'Contact Form Submission');
      dataLayer.push({'event': 'gtm-contact-form'}); //GTM Google Ads conversion tracking
      this.sendLastClickedCTA();
    }
  }

  errorCallback(result) {
    fetch(process.env.PLATFORM_HOST + '/api/v1/contact/error', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(result),
    })
    .catch(err => {
      console.log(err)
    })
  }

  render() {
    return (
      <div className="ContactForm d-flex flex-column justify-content-between">
      <FadeInOutAnimation showContent={this.state.thank_you_message} timeout={1000}>
          { this.state.thank_you_message
            ? <ThankYou/>
            :  <form id={`gtm-contact-form`}
                    className="m-lg-4">
                <div className="ContactForm__form-box mb-4">
                  <Input
                    className="fs-mask-without-consent"
                    name="firstname"
                    value={this.state.fields.firstname}
                    inputName={`form-control ${this.state.errors["firstname"] ? "error" : ""}`}
                    required
                    handleChange={(event) => this.handleUserInput(event)}>
                    First name
                  </Input>
                  <Input
                    className="fs-mask-without-consent"
                    name="lastname"
                    value={this.state.fields.lastname}
                    inputName={`form-control ${this.state.errors["lastname"] ? "error" : ""}`}
                    handleChange={(event) => this.handleUserInput(event)}>
                    Last name
                  </Input>
                  <Input
                    className="fs-mask-without-consent"
                    name="email"
                    type="email"
                    value={this.state.fields.email}
                    inputName={`form-control ${this.state.errors["email"] ? "error" : ""}`}
                    required
                    handleChange={(event) => this.handleUserInput(event)}>
                    Company Email
                  </Input>
                  <Input
                    className="fs-mask-without-consent"
                    name="phone"
                    type="tel"
                    value={this.state.fields.phone}
                    inputName={`form-control ${this.state.errors["phone"] ? "error" : ""}`}
                    handleChange={(event) => this.handleUserInput(event)}>
                    Phone number
                  </Input>
                  <Textarea
                    name="message"
                    value={this.state.fields.message}
                    handleChange={(event) => this.handleUserInput(event)}
                    className="form-control"
                  />
                  <Checkbox
                    name="contentForMarketing"
                    type="checkbox"
                    value={this.state.fields.contentForMarketing}
                    handleChange={(event) => this.handleUserInput(event)}
                    className="Checkbox__MarketingContentText">
                    <MarketingContentText />
                  </Checkbox>
                  <input
                    name="ab_testing"
                    className="d-none"
                    value={process.env.DEPLOYED_BRANCH}
                    readOnly/>
                </div>
              </form>
            }
        </FadeInOutAnimation>
      <div>
        <div className="ContactForm__form-buttons">
          <button disabled={this.state.loading || this.state.thank_you_message || !this.state.form_valid}
                type="submit"
                id="contact_submit"
                onClick={this.onSubmit}
                className="
                  btn
                  k-text--heading-work
                  btn-block
                  btn-xs-inline-block
                  d-flex
                  align-items-center
                  justify-content-center
                  mt-3
                  mt-md-0"
          >
            { this.state.loading
              ? <Loader />
              : 'send'
            }
          </button>
        </div>

        <FadeInOutAnimation showContent={this.state.thank_you_message} timeout={1000}>
          { this.state.thank_you_message
            ? <p>&nbsp;</p>
            : <MarketingTermsText className="MarketingTermsText small" />}
        </FadeInOutAnimation>

        {/* Error message */}
        {this.state.errorActive
          && <ContactErrors errors={this.state.errors} /> }

        {(this.state.response && this.state.status !== 200)
          && <ResponseHandler status={this.state.status}
                              response={this.state.response} /> }
        </div>
      </div>
    )
  }
}

export default Form