import React from 'react'

const Loader = (props) => (
  <div className={`
    Loader
    ${props.light ? 'Loader--light' : ''}
    ${props.large ? 'Loader--large' : ''}
    ${props.className}
    `}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default Loader
