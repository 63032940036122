import React from 'react'
import { SwitchTransition, CSSTransition } from "react-transition-group";
import './animations.sass'

const FadeInOutAnimation = ({ showContent, timeout, children }) => (
  <SwitchTransition mode="out-in">
    <CSSTransition key={showContent}
                    timeout={timeout}
                    addEndListener={(node, done) => {
                      node.addEventListener("transitionend", done, false);
                    }}
                    classNames="fade-in-out">
      {children}
    </CSSTransition>
  </SwitchTransition>
)

export default FadeInOutAnimation