import React from 'react'
import { strToEnglish } from './form-helpers'

const Textarea = (props) => (
  <div className={`form-group form-group__${props.name}`}>
    <textarea
      name={props.name}
      className={`form-control ${props.className}`}
      id={props.name}
      placeholder={strToEnglish(props.name)}
      value={props.value}
      onChange={props.handleChange} />
  </div>
)

export default Textarea
