import React from 'react'

const getErrors = (errors) => {
  return Object.keys(errors).map((error, i) => {
    if (errors[error] !== "") {
      return (
        <div key={i}>
          {errors[error]}
        </div>
      )
    }
  })
}

const ContactErrors = (props) => (
  <div className="ContactForm__errors mt-3">
    { props.errors &&
      <div className="text-danger">
        {getErrors(props.errors)}
      </div>
    }
  </div>
)

export default ContactErrors
