export const getCookie = cname => {
  let name = `${cname}=`
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const strToEnglish = str => {
  str = str.replace(/_/g, ' ')
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const consentForProcessWording = 'By submitting this form, I can confirm I have read and accepted Kortical\'s <a href="/legal/privacy-policy" target="_blank" class="k-text--link">privacy policy</a>.'
export const contentForMarketingWording = 'We will be mindful of your inbox. Please tick this box so we can let you know when we release any new products or discounts, etc.'
export const consentForProcessWordingDataPrep = 'I\'ve read the <a href="/legal/privacy-policy" target="_blank" class="k-text--link">Privacy Policy</a> & <a href="/legal/terms-and-conditions" target="_blank" class="k-text--link">Terms of Service</a>'

export const formatDataForHubspot = (fields, contentForMarketingId = false) => {
  let data = {
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
    fields: [],
  }


  if (contentForMarketingId) {
    data.legalConsentOptions = {
      consent: {
        consentToProcess: true,
        text: consentForProcessWording,
        communications: [
          {
            subscriptionTypeId: contentForMarketingId,
            value: fields.contentForMarketing,
            text: contentForMarketingWording,
          },
        ],
      },
    }
  }

  let hutk = getCookie('hubspotutk')
  if (hutk) {
    data.context.hutk = hutk
  }

  for (let key of Object.keys(fields)) {
    if (key !== 'contentForMarketing') {
      data.fields.push({
        name: key,
        value: fields[key]
      })
    }
  }

  return JSON.stringify(data)
}

const getQueryParameterFromWindowLocation = (name) => {
  // for parameter name 'foo' will match 'bar' in the following string
  // 'google.com?foo=bar&hello=nick#test-page' as its first capture
  // group i.e. results[1]. For parameter name 'hello' will match nick
  // and ignore '#test-page'
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  let results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const setFieldsFromQuery = (fields) => {
  for (let key of Object.keys(fields)){
    let urlParamVal = getQueryParameterFromWindowLocation(key)
    fields[key] = urlParamVal ? urlParamVal : fields[key]
  }
  return fields
}

export const passwordValidatonIssue = (password, setErrorMessage) => {
  const errorMessage = 'Your password must contain at least 14 characters, or 8 characters and at least 1 upper case, 1 lower case and a special or numeric character.'

  if (password.includes(' ')) {
    setErrorMessage("Password must not contain white space");
    return true;
  }

  if (password.length >= 14)
    return;

  // 8 characters and at least 1 upper case, 1 lower case and a special or numeric character.
  const regex = /^(?=.{8,})(?=.*[a-z])(?=.*[0-9]||[@#$%^!&*+=])(?=.*[A-Z]).*$/g;
  if (password.match(regex))
    return;

  setErrorMessage(errorMessage);
  return true;
}

export const emailValidationIssue = (email, setErrorMessage, can_be_empty=false) => {
  if ((can_be_empty && !email) || (can_be_empty && email.length === 0 )) {
    return;
  }
  else if (email.includes('@') && email.includes('.') && email.length > 5){
    return;
  }

  setErrorMessage('Email must be a valid email');
  return true;
}
