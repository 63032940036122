import { Component } from 'react';
import { formatDataForHubspot } from './form-helpers';

class Form extends Component {

  constructor(props) {
    super(props)
    this.submitUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/2608561/'
  }

  getBrowserInfo = () => {
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset+6);

       if ((verOffset=nAgt.indexOf("Version"))!=-1)
         fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset+7);

      if ((verOffset=nAgt.indexOf("Version"))!=-1)
        fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
              (verOffset=nAgt.lastIndexOf('/')) )
    {
      browserName = nAgt.substring(nameOffset,verOffset);
      fullVersion = nAgt.substring(verOffset+1);

      if (browserName.toLowerCase()==browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
      fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
      fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);

    if (isNaN(majorVersion)) {
      fullVersion  = ''+parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion,10);
    }

    return "Browser name= "+browserName+", Full version= "+fullVersion+", Major version= "+majorVersion+"navigator.appName= "+navigator.appName+", navigator.userAgent= "+navigator.userAgent+"";
  }

  onSubmit = async event => {
    event.preventDefault()

    this.setState({
      status: 0,
      response: null,
      responseMessage: null,
      loading: true,
    })

    let data = formatDataForHubspot(this.state.fields, this.contentForMarketingId)
    let result = await this.post(data)

    this.setState({
      status: result.status,
      response: result.json,
      loading: false,
      form_valid: false
    })

    if (result.status === 200 && !result.json.errors && this.successCallback) {
      this.successCallback()
    } else {
      data = {
        errors: result.json.errors,
        firstname: this.state.fields.firstname,
        lastname: this.state.fields.lastname,
        email: this.state.fields.email,
        message: this.state.fields.message,
        phone: this.state.fields.phone,
        start_end_time: this.state.start_time + " to " + this.getTime(),
        browser_details: this.getBrowserInfo()
      }

      this.errorCallback(data)
    }
  }

  async post(data) {
    let result = await fetch(
      this.submitUrl + this.formId, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: data,
      }
    )
    let resultJson = await result.json()
    return {
      status: result.status,
      json: resultJson,
    }
  }
}

export default Form
